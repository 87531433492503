import React from "react";
import { MapContainer, GeoJSON } from "react-leaflet";
import mapData from "./nigeria_geojson.json";
import "leaflet/dist/leaflet.css";
import L from "leaflet";
import { useTheme, useMediaQuery } from "@mui/material";

const MyMap = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const isTablet = useMediaQuery(theme.breakpoints.between("sm", "md"));
  const isDesktop = useMediaQuery(theme.breakpoints.up("md"));

  const countryStyle = {
    fillColor: "#FC5719",
    fillOpacity: 1,
    color: "black",
    weight: 2,
  };

  const onEachCountry = (country, layer) => {
    const countryName = country.properties.state;
    const fund = country.properties.fund || "5000"; // Assuming fund information is available in properties
    layer.on('mouseover', function(e) {
        layer.bindPopup(`${countryName} </br> -Total Fund spent: ${fund}`).openPopup();
    });

    layer.options.fillOpacity = Math.random();
  };

  const addTitleControl = (map) => {
    const title = L.control({ position: 'topleft' });

    title.onAdd = function () {
      const div = L.DomUtil.create('div', 'map-title');
      div.innerHTML = '<h4>Map Location of Spending</h4>';
      div.style.marginTop = '25px'; // Example: add margin-top
      div.style.color = 'black';
      return div;
    };

    title.addTo(map);
  };

  const getZoomLevel = () => {
    if (isMobile) return 5;
    if (isTablet) return 6;
    if (isDesktop) return 7;
    return 6; // Default zoom level
  };

  return (
    <div>
      <MapContainer
        style={{
          height: "65vh",
          marginTop: '80px',
          backgroundColor: '#fff',
          maxWidth: '100%',
          marginLeft: isMobile ? '0' : isTablet ? '50px' : '100px',
          borderRadius: '10px',
        }}
        zoom={getZoomLevel()}
        center={[10, 8]}
        zoomControl={false}
        attributionControl={false}
        whenCreated={mapInstance => {
          addTitleControl(mapInstance);
        }}
      >
        <GeoJSON
          style={countryStyle}
          data={mapData.features}
          onEachFeature={onEachCountry}
        />
      </MapContainer>
    </div>
  );
};

export default MyMap;