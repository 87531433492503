import JwtService from './jwtService';
import * as url from './url_helper';
// Gets the logged in user data from local session
const getLoggedInUser = () => {
  const user = localStorage.getItem('user');
  if (user) return JSON.parse(user);
  return null;
};

//is user is logged in
const isUserAuthenticated = () => {
  return getLoggedInUser() !== null;
};

// Login Method
const postLogin = (data) => {
  return JwtService.post(url.POST_LOGIN, data);
};

// postForgetPwd
const postForgetPwd = (data) => JwtService.post(url.POST_PASSWORD_FORGET, data);

// Register Method
const postJwtRegister = (data) => {
  return JwtService.post(url.POST_REGISTER, data);
};

// register Admin
const postJwtAdmin = (data) => {
  return JwtService.post(url.POST_ADMIN_REGISTER, data);
};
const eventLog = (data) => {
  return JwtService.post(url.EVENT_LOG, data);
};
// postForgetPwd
const postJwtForgetPwd = (data) =>
  JwtService.post(url.POST_JWT_PASSWORD_FORGET, data);

export const postresetPwd = (data) =>
  JwtService.post(url.POST_JWT_PASSWORD_RESET, data);

// change password
export const postChangePassword = (data) =>
  JwtService.post(url.CHANGE_PASSWORD, data);

export const postUpdatePassword = (data) =>
  JwtService.post(url.UPDATE_PASSWORD, data);

// change password OTP
export const postChangePasswordOtp = (data) =>
  JwtService.post(url.CHANGE_PASSWORD_OTP, data);

// get contacts
export const getUsers = () => JwtService.get(url.GET_USERS);

// get admin setting list
export const getAdminUsers = (params) =>
  JwtService.post(url.GET_ADMIN_USERS, params);
  export const getWalletHistory = (params) =>
    JwtService.post(url.GET_WALLET_HISTORY, params);
  // assign permission to secondary user
  export const assignPermissionSecondaryUser = (params) => {
    return JwtService.post(url.ASSIGN_PERMISSION_SECONDARY_USER, params);
  };

  // get roles
  // export const getRoles = () => JwtService.get(url.GET_ROLES);

  export const getUserProfile = () => JwtService.get(url.GET_USER_PROFILE);

  // get clients
  export const getClients = (params) => {
    return JwtService.post(url.GET_CLIENTS, params);
  };
  export const getConsent = (params) => {
    return JwtService.get(
      `/api/v1/bporchestrator/entitlement/clients/${params?.state?.client}/consents`,
      params
    );
  };
  export const getWalletDetails = (params) => {
    return JwtService.get(`/api/v1/businessPartner/walletDetails`, params);
  };
  export const getTemplate = (params) => {
    return JwtService.post(
      `/api/v1/businessPartner/mandate-template/list`,
      params
    );
  };
  export const getPayment = (params) => {
    return JwtService.post(
      `/api/v1/bporchestrator/mandate/payments/list`,
      params
    );
  };
  export const getPayoutSummery = (params) => {
    return JwtService.get(`/api/v1/bporchestrator/mandate/payouts/summary`, params);
  };

  export const getPayouts = (params) => {
    return JwtService.post(`/api/v1/bporchestrator/mandate/payouts/list`, params);
  };
 
  export const getPaymentSummary = () => {
    return JwtService.get(`/api/v1/bporchestrator/mandate/payments/summary`);
  };
  export const getCustomer = (payload) => {
    return JwtService.post(
      `/api/v1/bporchestrator/mandate/users/list`,
      payload
    );
  };
  export const getMandateList = (payload) => {
    return JwtService.post(`/api/v1/bporchestrator/mandate/list`, payload);
  };
  export const setTemplate = (params) => {
    return JwtService.post(
      `/api/v1/businessPartner/mandate-template/add`,
      params
    );
  };
export const sendEmailConsent = (params) => {
  return JwtService.post(
    `/api/v1/bporchestrator/entitlement/client/consentRequestEmail`,
    params
  );
};
export const postStatement = (params, urlEndPoint) => {
  return JwtService.post(
    `/api/v1/bporchestrator/partner-dashboard/${urlEndPoint}`,
    params
  );
};
export const getRisk = (params) => {
  return JwtService.post(
    `/api/v1/bporchestrator/partner-dashboard/get-risk`,
    params
  );
};
export const getDashboard = (params) => {
  return JwtService.post(
    `/api/v1/bporchestrator/partner-dashboard/get-dashboard`,
    params
  );
};
export const getKYC = (params) => {
  return JwtService.post(
    `/api/v1/bporchestrator/partner-dashboard/get-kyc`,
    params
  );
};

export const getCategories = (params) => {
  return JwtService.post(
    `/api/v1/bporchestrator/partner-dashboard/get-category-insights`,
    params
  );
};
export const postCode = (params) => {
  return JwtService.post(
    `/api/v1/bporchestrator/entitlement/client/addByReferralCode`,
    params
  );
};

// get clients
export const getBankList = (params) => {
  return JwtService.get(`${url.BANK_LIST}/${params}`);
};

export const getAccountByBank = (params) => {
  return JwtService.post(url.ACCOUNT_BY_BANK, params);
};

export const getDashboardDetail = (params) => {
  return JwtService.post(url.DASHBOARD_DETAIL, params);
};
export const banksList = (params) => {
  return JwtService.get(url.GET_BANKS, params);
};
export const getBusinessProfile = (params) => {
  return JwtService.get(url.GET_BUSINESS_PROFILE,params);
};
export const updateBusinessProfile = (params) => {
  return JwtService.put(url.UPDATE_BUSINESS_PROFILE,params);
};
export {
  getLoggedInUser,
  isUserAuthenticated,
  postForgetPwd,
  postLogin,
  postJwtAdmin,
  eventLog,
  postJwtForgetPwd,
  postJwtRegister,
};
