import React from 'react';
import { Box, Card, CardContent, Typography, List, ListItem, ListItemText } from '@mui/material';

const categories = ['Groceries', 'Dining Out', 'Gambling', 'Fuel', 'Investments', 'Loans/EMIs'];

const SpendingCategory = () => {
  return (
    <Box
          sx={{
            marginTop: '10px',
            backgroundColor: '#fff',
            borderRadius: '8px',
            height: '380px',
            maxWidth: '400px',
            padding: '15px',
          }}
        >
      
        <Typography variant="h6" sx={{ marginBottom: '10px', marginTop:'1px' }}>
          Spending Category
        </Typography>
        <Typography variant="body2" sx={{ marginBottom: '10px' }}>
          Various categories where the funds were utilized
        </Typography>
        <List >
          {categories.map((item, index) => (
            <ListItem key={index} disablePadding sx={{ marginBottom: '10px' }}>
              <ListItemText primary={`${index + 1}. ${item}`} />
            </ListItem>
          ))}
        </List>
      
    </Box>
  );
};

export default SpendingCategory;
