import React from "react";
import { Box, Grid } from "@mui/material";
import SpendingCategory from "./spendingCategory";
import AnomalyDetection from "./anomalyDetection";
import { RootStyle, MainStyle } from "../../../components/Common/Style";
import IncomeVerificationUINav from "../../../components/Navbar/DashboardUINav/IncomeVerificationUINav";
import { useLocation } from "react-router-dom";
import MyMap from "./myMap";
import { useTheme, useMediaQuery } from "@mui/material";

function App() {
  const location = useLocation();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const isTablet = useMediaQuery(theme.breakpoints.between("sm", "md"));
  const isDesktop = useMediaQuery(theme.breakpoints.up("md"));

  return (
    <RootStyle>
      <IncomeVerificationUINav location={location} />
      <MainStyle>
        <Box>
          <Grid container spacing={2}>
            <Grid item xs={12} md={isTablet ? 6 : 8}>
              <MyMap />
            </Grid>
            <Grid item xs={12} md={isTablet ? 6 : 4} mt={isMobile ? 2 : isTablet ? 5 : 9}>
              <SpendingCategory />
              <Grid item xs={12}>
                <AnomalyDetection anomaliesCount={2} />
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </MainStyle>
    </RootStyle>
  );
}

export default App;