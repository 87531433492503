import { Box, Button, Grid, Stack, Typography } from "@mui/material";
import React from "react";
import { BoxWrapper, FormCard } from "./common/AuthStyle";
import FormLogo from "./common/FormLogo";
import RSuccessIcon from "../../assets/svg/rsuccess.svg";

const RegistrationSuccess = () => {
  return (
    <BoxWrapper>
      <FormLogo />
      <FormCard>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Stack direction={"column"} justifyContent={"center"}>
              <Box
                component={"img"}
                src={RSuccessIcon}
                sx={{
                  height: 180,
                }}
              />
            </Stack>
          </Grid>
          <Grid item xs={12}>
            <Stack gap={2} textAlign={"center"}>
              <Typography variant="Body_semibold_28" color="text.primary">
                Registration Successful!
              </Typography>
              <Typography variant="Body_light_14" fontWeight={400} gutterBottom>
                Thanks for verifying your email address. Now taking you to e-doc
                online...
              </Typography>
            </Stack>
          </Grid>

          <Grid item xs={12} mt={2}>
            <Button variant="contained" fullWidth>
              Done
            </Button>
          </Grid>
        </Grid>
      </FormCard>
    </BoxWrapper>
  );
};

export default RegistrationSuccess;
