import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  Grid,
  IconButton,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import {
  BottomText,
  BoxWrapper,
  Form,
  FormCard,
  PolicyText,
} from './common/AuthStyle';
import FormLogo from './common/FormLogo';
import PhoneInput from 'react-phone-number-input';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { postJwtAdmin } from '../../helpers/backend_helper';
import {
  useHistory,
  useLocation,
} from 'react-router-dom/cjs/react-router-dom.min';
import { toast } from 'react-toastify';

const Register = () => {
  const history = useHistory();
  const location = useLocation();
  const [formData, setFormData] = useState({
    companyName: '',
    firstName: '',
    lastName: '',
    email: '',
    password: '',
    phoneNumber: '',
    isChecked: false,
    showPassword: false,
  });

  const [userType] = useState(location.pathname.split('/')[2]);
  console.log('userType', userType);
  const [errors, setErrors] = useState({});

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: type === 'checkbox' ? checked : value,
    }));
  };

  const handlePhoneChange = (value) => {
    setFormData((prev) => ({
      ...prev,
      phoneNumber: value,
    }));
  };

  const handleTogglePasswordVisibility = () => {
    setFormData((prev) => ({
      ...prev,
      showPassword: !prev.showPassword,
    }));
  };

  const validate = () => {
    let tempErrors = {};
    tempErrors.companyName =
      !formData.companyName && userType === 'company'
        ? 'Company Name is required.'
        : '';
    tempErrors.firstName = formData.firstName ? '' : 'First Name is required.';
    tempErrors.lastName = formData.lastName ? '' : 'Last Name is required.';
    tempErrors.email = formData.email ? '' : 'Email is required.';
    tempErrors.password = formData.password ? '' : 'Password is required.';
    tempErrors.phoneNumber = formData.phoneNumber
      ? ''
      : 'Business Contact Number is required.';
    tempErrors.isChecked = formData.isChecked
      ? ''
      : 'You must agree to the terms and conditions.';
    setErrors(tempErrors);
    return Object.values(tempErrors).every((x) => x === '');
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validate()) {
      console.log('formData', formData);
      const payload = {
        companyName:
          formData.companyName || `${formData.firstName} ${formData.lastName}`,
        phoneNumber: formData.phoneNumber,
        emailAddress: formData.email,
        accountType: userType === 'company' ? 'Company' : 'Individual',
        users: [
          {
            firstName: formData.firstName,
            lastName: formData.lastName,
            email: formData.email,
            userType: 'P',
            phoneNumber: formData.phoneNumber,
            password: formData.password,
          },
        ],
      };
      postJwtAdmin(payload)
        .then((res) => {
          if (res.codes === 200) {
            history.push('/verify-email', { email: formData.email });
          }
        })
        .catch((err) => {
          toast.error('Received error while creating user');
        });
      // Handle form submission
      console.log('Form submitted successfully');
    }
  };

  return (
    <BoxWrapper sx={{ py: 3 }}>
      <FormLogo />
      <FormCard>
        <Stack gap={1}>
          <Typography variant="Body_semibold_28" color="text.primary">
            Sign up
          </Typography>
          <Typography variant="Body_light_14" fontWeight={400} gutterBottom>
            Seems you are new here, Let’s set up your profile.
          </Typography>
        </Stack>
        <Form onSubmit={handleSubmit}>
          <Grid container spacing={2}>
            {userType === 'company' && (
              <Grid item xs={12}>
                <Stack gap={1}>
                  <Typography variant="Body_medium_16">Company Name</Typography>
                  <TextField
                    fullWidth
                    variant="outlined"
                    type="text"
                    name="companyName"
                    value={formData.companyName}
                    onChange={handleChange}
                    placeholder="Enter company name"
                    error={Boolean(errors.companyName)}
                    helperText={errors.companyName}
                  />
                </Stack>
              </Grid>
            )}

            <Grid item xs={6}>
              <Stack gap={1}>
                <Typography variant="Body_medium_16">First Name</Typography>
                <TextField
                  fullWidth
                  variant="outlined"
                  type="text"
                  name="firstName"
                  value={formData.firstName}
                  onChange={handleChange}
                  placeholder="Enter first name"
                  error={Boolean(errors.firstName)}
                  helperText={errors.firstName}
                />
              </Stack>
            </Grid>

            <Grid item xs={6}>
              <Stack gap={1}>
                <Typography variant="Body_medium_16">Last Name</Typography>
                <TextField
                  fullWidth
                  variant="outlined"
                  type="text"
                  name="lastName"
                  value={formData.lastName}
                  onChange={handleChange}
                  placeholder="Enter last name"
                  error={Boolean(errors.lastName)}
                  helperText={errors.lastName}
                />
              </Stack>
            </Grid>

            <Grid item xs={12}>
              <Stack gap={1}>
                <Typography variant="Body_medium_16">Email</Typography>
                <TextField
                  fullWidth
                  variant="outlined"
                  type="email"
                  name="email"
                  value={formData.email}
                  onChange={handleChange}
                  error={Boolean(errors.email)}
                  helperText={errors.email}
                />
              </Stack>
            </Grid>

            <Grid item xs={12}>
              <Stack gap={1}>
                <Typography variant="Body_medium_16">Password</Typography>
                <TextField
                  fullWidth
                  variant="outlined"
                  type={formData.showPassword ? 'text' : 'password'}
                  name="password"
                  value={formData.password}
                  onChange={handleChange}
                  InputProps={{
                    endAdornment: (
                      <IconButton
                        onClick={handleTogglePasswordVisibility}
                        edge="end"
                      >
                        {formData.showPassword ? (
                          <VisibilityOff />
                        ) : (
                          <Visibility />
                        )}
                      </IconButton>
                    ),
                  }}
                  error={Boolean(errors.password)}
                  helperText={errors.password}
                />
              </Stack>
            </Grid>

            <Grid item xs={12}>
              <Stack gap={1}>
                <Typography variant="Body_medium_16">
                  Business Contact Number
                </Typography>
                <PhoneInput
                  placeholder="000 000 0000"
                  addInternationalOption={false}
                  defaultCountry={'NG'}
                  countries={['NG', 'GB']}
                  value={formData.phoneNumber}
                  onChange={handlePhoneChange}
                />
                {errors.phoneNumber && (
                  <Typography color="error" variant="caption">
                    {errors.phoneNumber}
                  </Typography>
                )}
              </Stack>
            </Grid>

            <Grid item xs={12}>
              <FormControlLabel
                control={
                  <Checkbox
                    name="isChecked"
                    checked={formData.isChecked}
                    onChange={handleChange}
                    color="primary"
                  />
                }
                label={
                  <PolicyText>
                    By clicking “Allow” you also agree to our{' '}
                    <Link to="#">Terms of Use</Link> and{' '}
                    <Link to="#">Privacy Policy</Link>
                  </PolicyText>
                }
              />
              {errors.isChecked && (
                <Typography color="error" variant="caption">
                  {errors.isChecked}
                </Typography>
              )}
            </Grid>
          </Grid>

          <Box mt={3}>
            <Button
              variant="contained"
              fullWidth
              type="submit"
              disabled={!formData.isChecked}
            >
              Create an Account
            </Button>
          </Box>

          <BottomText>
            Already have an account? <Link to="/login">Login</Link>
          </BottomText>
        </Form>
      </FormCard>
    </BoxWrapper>
  );
};

export default Register;