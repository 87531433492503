import { Box, Button, Grid, Stack, Typography } from "@mui/material";
import React from "react";
import { BoxWrapper, FormCard } from "./common/AuthStyle";
import FormLogo from "./common/FormLogo";
import VEmail from "../../assets/svg/vemail.svg";
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';

const VerifyEmail = () => {
  const location = useLocation();
  console.log('location', location);
  return (
    <BoxWrapper>
      <FormLogo />
      <FormCard>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Stack direction={'column'} justifyContent={'center'}>
              <Box
                component={'img'}
                src={VEmail}
                sx={{
                  height: 180,
                }}
              />
            </Stack>
          </Grid>
          <Grid item xs={12}>
            <Stack gap={2} textAlign={'center'}>
              <Typography variant="Body_semibold_28" color="text.primary">
                Verify your email
              </Typography>
              <Typography variant="Body_light_14" fontWeight={400} gutterBottom>
                we sent a verification email to <b>{location?.state?.email}</b>{' '}
                Check your inbox for next step.{' '}
              </Typography>
            </Stack>
          </Grid>

          <Grid item xs={12} mt={2}>
            <Button variant="contained" fullWidth>
              Resend Email
            </Button>
          </Grid>
        </Grid>
      </FormCard>
    </BoxWrapper>
  );
};

export default VerifyEmail;
