import { Box, styled } from "@mui/material";
import { Link } from "react-router-dom";

export const BoxWrapper = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  minHeight: "100vh",
  backgroundColor: "#F9F9F9",
}));

export const FormCard = styled(Box)(({ theme }) => ({
  maxWidth: "480px",
  padding: theme.spacing(4.5),
  background: theme.palette.common.white,
  border: "1px solid rgba(33, 33, 32, 0.1)",
  borderRadius: theme.spacing(2),

  [theme.breakpoints.down("sm")]: {
    width: "90%",
    maxWidth: "90%",
    padding: theme.spacing(3),
  },
}));

export const Form = styled("form")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  marginTop: theme.spacing(4.5),
  width: "100%",
}));

export const IconBox = styled("form")(({ theme }) => ({
  background: "#FFF7F3",
  width: "44px",
  height: "44px",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  cursor: "pointer",
  borderRadius: "10px",
}));

export const ForgotPasswordLink = styled(Link)(({ theme }) => ({
  ...theme.typography.Body_medium_16,
  color: theme.palette.orange.main,
  width: "fit-content",
}));

export const BottomText = styled(Box)(({ theme }) => ({
  ...theme.typography.Body_medium_16,
  color: theme.palette.text.main,
  marginTop: theme.spacing(3),
  textAlign: "center",

  a: {
    color: theme.palette.orange.main,
    textDecoration: "underline",
    "&:hover": {
      color: theme.palette.orange.dark,
    },
  },
}));

export const PolicyText = styled(Box)(({ theme }) => ({
  ...theme.typography.Body_medium_16,
  color: theme.palette.text.main,

  a: {
    color: theme.palette.orange.main,
    textDecoration: "underline",
    "&:hover": {
      color: theme.palette.orange.dark,
    },
  },
}));
