import {
  Box,
  Button,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  Stack,
  styled,
  Typography,
} from '@mui/material';
import React, { useState } from 'react';

import CompanyIcon from '../../assets/svg/company.svg';
import PersonIcon from '../../assets/svg/person.svg';
import { BoxWrapper, Form, FormCard, IconBox } from './common/AuthStyle';
import FormLogo from './common/FormLogo';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';

const FormStack = styled(Stack)(({ theme }) => ({
  background: theme.palette.common.white,
  width: '100%',
  padding: '10px',
  border: ' 1px solid rgba(0, 24, 35, 0.1)',
  borderRadius: '15px',
}));

const RegisterOption = () => {
  const history = useHistory();
  const [registrationType, setRegistrationType] = useState('individual');

  const handleNextClick = () => {
    if (registrationType === 'company') {
      history.push('/r/company');
    } else {
      history.push('/r/individual');
    }
  };

  return (
    <BoxWrapper>
      <FormLogo />
      <FormCard>
        <Stack gap={1}>
          <Typography variant="Body_semibold_28" color="text.primary">
            Sign up
          </Typography>
          <Typography variant="Body_light_14" fontWeight={400} gutterBottom>
            Seems you are new here, Let’s set up your profile.
          </Typography>
        </Stack>

        <Form>
          <RadioGroup
            value={registrationType}
            name="registrationType"
            onChange={(e) => setRegistrationType(e.target.value)}
          >
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <FormStack direction={'row'} alignItems={'center'} gap={2}>
                  <IconBox>
                    <Box component={'img'} src={CompanyIcon} />
                  </IconBox>

                  <FormControlLabel
                    value="company"
                    control={<Radio />}
                    label="Register as a Company"
                    labelPlacement="start"
                    fullWidth
                    sx={{ flex: 1, margin: 0, justifyContent: 'space-between' }}
                  />
                </FormStack>
              </Grid>

              <Grid item xs={12}>
                <FormStack direction={'row'} alignItems={'center'} gap={2}>
                  <IconBox>
                    <Box component={'img'} src={PersonIcon} />
                  </IconBox>

                  <FormControlLabel
                    value="individual"
                    control={<Radio />}
                    label="Register as an Individual person"
                    labelPlacement="start"
                    fullWidth
                    sx={{ flex: 1, margin: 0, justifyContent: 'space-between' }}
                  />
                </FormStack>
              </Grid>
            </Grid>
          </RadioGroup>

          <Box mt={3}>
            <Button variant="contained" fullWidth onClick={handleNextClick}>
              Next
            </Button>
          </Box>
        </Form>
      </FormCard>
    </BoxWrapper>
  );
};

export default RegisterOption;
