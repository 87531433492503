import {
  Button,
  Grid,
  Stack,
  TextField,
  Typography,
  IconButton,
} from "@mui/material";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import {
  BottomText,
  BoxWrapper,
  ForgotPasswordLink,
  Form,
  FormCard,
} from "./common/AuthStyle";
import FormLogo from "./common/FormLogo";
import { postLogin } from '../../helpers/backend_helper';
import { toast } from 'react-toastify';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { getLogedinUser } from '../../helpers/commonFunctions';

const Login = () => {
  const history = useHistory();
  const [formData, setFormData] = useState({
    email: '',
    password: '',
    showPassword: false,
  });

  const [errors, setErrors] = useState({});

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleTogglePasswordVisibility = () => {
    setFormData((prev) => ({
      ...prev,
      showPassword: !prev.showPassword,
    }));
  };

  const validate = () => {
    let tempErrors = {};
    tempErrors.email = formData.email ? '' : 'Email is required.';
    tempErrors.password = formData.password ? '' : 'Password is required.';
    setErrors(tempErrors);
    return Object.values(tempErrors).every((x) => x === '');
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validate()) {
      postLogin(formData)
        .then((response) => {
          if (response.codes === 200) {
            response.data['userType'] =
              response.data.userType !== undefined
                ? response.data.userType
                : 'P';
            localStorage.setItem('authUser', JSON.stringify(response));
            localStorage.setItem('reviewStatus', response?.data?.reviewStatus);
            toast.success('Login successful');
            if (
              response.data.reviewStatus === 'KYC_PENDING' ||
              response.data.reviewStatus === 'PENDING'
            ) {
              let authUser = getLogedinUser();
              debugger;
              if (authUser?.name === authUser.companyName) {
                history.push('/kyc-individual');
              } else {
                history.push('/kyc-business');
              }
            } else {
              history.push('/client-list');
            }
            // setLoading(false);
          } else {
            toast.error(response.message);
            // setLoading(false);
          }
        })
        .catch((error) => {});
    }
  };

  return (
    <BoxWrapper>
      <FormLogo />
      <FormCard>
        <Stack gap={1}>
          <Typography variant="Body_semibold_28" color="text.primary">
            Login
          </Typography>
          <Typography variant="Body_light_14" fontWeight={400} gutterBottom>
            Welcome back! Please enter your details.
          </Typography>
        </Stack>
        <Form onSubmit={handleSubmit}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Stack gap={1}>
                <Typography variant="Body_medium_16">Email</Typography>
                <TextField
                  fullWidth
                  variant="outlined"
                  type="email"
                  name="email"
                  value={formData.email}
                  onChange={handleChange}
                  error={Boolean(errors.email)}
                  helperText={errors.email}
                />
              </Stack>
            </Grid>

            <Grid item xs={12}>
              <Stack gap={1}>
                <Typography variant="Body_medium_16">Password</Typography>
                <TextField
                  fullWidth
                  variant="outlined"
                  type={formData.showPassword ? 'text' : 'password'}
                  name="password"
                  value={formData.password}
                  onChange={handleChange}
                  InputProps={{
                    endAdornment: (
                      <IconButton
                        onClick={handleTogglePasswordVisibility}
                        edge="end"
                      >
                        {formData.showPassword ? (
                          <VisibilityOff />
                        ) : (
                          <Visibility />
                        )}
                      </IconButton>
                    ),
                  }}
                  error={Boolean(errors.password)}
                  helperText={errors.password}
                />
              </Stack>
            </Grid>

            <Grid item xs={12}>
              <Stack alignItems={'end'}>
                <ForgotPasswordLink to="#">Forgot Password?</ForgotPasswordLink>
              </Stack>
            </Grid>

            <Grid item xs={12}>
              <Button variant="contained" fullWidth type="submit">
                Login
              </Button>
            </Grid>
          </Grid>
        </Form>

        <BottomText>
          Don’t have an account? <Link to="/register">Create Account</Link>
        </BottomText>
      </FormCard>
    </BoxWrapper>
  );
};

export default Login;