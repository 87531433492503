import React, { useState } from "react";
import {
  Box,
  Grid,
  Typography,
  Card,
  CardContent,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Select,
  MenuItem,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import ApexSingleLineChart from "./lineChart";
import SuccessImg from "../../assets/images/Success.png";
import { Link as RouterLink } from "react-router-dom";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import IncomeVerificationUINav from "../../components/Navbar/DashboardUINav/IncomeVerificationUINav";
import {
  MainStyle,
  RootStyle,
  StyledDivider2,
} from "../../components/Common/Style";
import { useLocation } from "react-router-dom";

const IncomeVerificationDashboard = () => {
  const [selectedMonth, setSelectedMonth] = useState("January");
  const location = useLocation();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const isTablet = useMediaQuery(theme.breakpoints.between("sm", "md"));
  const isDesktop = useMediaQuery(theme.breakpoints.up("md"));

  // Employment History Table Data
  const employmentHistory = [
    { company: "Gada Solutions", amount: "₦ 5000" },
    { company: "Sunrise Global", amount: "₦ 2000" },
    { company: "ByteBridge", amount: "₦ 1000" },
    { company: "TechNova Solutions", amount: "₦ 500" },
  ];

  // Chart Data
  const lineChartData = {
    categories: Array.from({ length: 31 }, (_, i) => i + 1), // Days 1 to 31
    values: [
      0, 0, 500, 0, 0, 0, 0, 0, 0, 0, 0, 5000, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
      1000, 0, 0, 0, 0, 0, 0, 0, 0,
    ],
  };

  return (
    <RootStyle>
      <IncomeVerificationUINav location={location} />
      <MainStyle>
        <Box sx={{ padding: isMobile ? 1 : 3 }}>
          {/* Header Section */}
          <Grid
            container
            spacing={3}
            alignItems="center"
            justifyContent="space-between"
          >
            <Grid item xs={12} mt={isMobile ? 2 : 5} ml={isMobile ? 2 : 12}>
              <Box
                display="flex"
                alignItems="center"
                justifyContent="space-between"
                flexDirection={isMobile ? "column" : "row"}
              >
                <Box display="flex" alignItems="center">
                  {/* <Typography
                    variant="h4"
                    fontWeight="bold"
                    color="orangered"
                    sx={{ display: "flex", alignItems: "center" }}
                  >
                    John Doe
                    <Box
                      component="img"
                      src={SuccessImg}
                      alt="Success"
                      ml={2}
                      sx={{ width: 24, height: 24 }}
                    />
                  </Typography> */}
                </Box>
                <Box mr={isMobile ? 0 : 8} sx={{ display: 'flex', alignItems: 'center', mt: isMobile ? 2 : 0 }}>
                  <Typography variant="body2" color="orangered" sx={{ mr: 2 }}>
                    Select Month
                  </Typography>
                  <Select
                    value={selectedMonth}
                    onChange={(e) => setSelectedMonth(e.target.value)}
                    size="small"
                    sx={{ 
                      minWidth: 120,
                      border: '1px solid orangered',
                      '& .MuiOutlinedInput-notchedOutline': {
                        borderColor: 'orangered',
                      },
                      '&:hover .MuiOutlinedInput-notchedOutline': {
                        borderColor: 'orangered',
                      },
                      '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                        borderColor: 'orangered',
                      }
                    }}
                  >
                    <MenuItem value="January">January</MenuItem>
                    <MenuItem value="February">February</MenuItem>
                  </Select>
                </Box>
              </Box>
            </Grid>

            {/* Employment History Table */}
            <Grid
              item
              xs={12}
              md={4}
              ml={isMobile ? 0 : 7}
              
            >
              <Card
                variant="outlined"
                sx={{ border: "1px solid #ccc", height: isMobile ? "auto" : "470px" }}
              >
                <CardContent>
                  <Typography variant="h6" gutterBottom>
                    Employment History
                  </Typography>
                  <Typography variant="body2" paragraph>
                    Below is the list of individuals who consistently make
                    payments to this account.
                  </Typography>
                  <TableContainer component={Paper}>
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell>
                            <strong>Company Name</strong>
                          </TableCell>
                          <TableCell>
                            <strong>Amount Credited</strong>
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {employmentHistory.map((item, index) => (
                          <TableRow
                            key={index}
                            sx={{
                              "&:nth-of-type(odd)": {
                                backgroundColor: "#f9f9f9",
                              },
                            }}
                          >
                            <TableCell>{item.company}</TableCell>
                            <TableCell>{item.amount}</TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </CardContent>
              </Card>
            </Grid>

            {/* Salary Line Chart */}
            <Grid item xs={12} md={7} mr={isMobile ? 0 : 7}>
              <Card>
                <CardContent>
                  <Box
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                    mb={2}
                    flexDirection={isMobile ? "column" : "row"}
                  >
                    <Typography variant="h6" fontWeight="bold">
                      Salary credited to this account
                    </Typography>
                    <Box display="flex" alignItems="center" mt={isMobile ? 2 : 0}>
                      <Box
                        sx={{
                          width: 12,
                          height: 12,
                          borderRadius: '50%',
                          backgroundColor: 'orange.main',
                          mr: 1,
                        }}
                      />
                      <Typography variant="body2" color="orange.main">
                        Amount Credited
                      </Typography>
                    </Box>
                  </Box>
                  <ApexSingleLineChart data={lineChartData} />
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </Box>
      </MainStyle>
    </RootStyle>
  );
};

export default IncomeVerificationDashboard;