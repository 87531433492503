import { Box, useTheme } from "@mui/material";
import React from "react";
import LogoActive from "../../../assets/images/logo-active.png";

const FormLogo = () => {
  const theme = useTheme();
  return (
    <Box
      component={"img"}
      sx={{
        height: 44,
        marginBottom: theme.spacing(3.5),
        display: "block",
        marginLeft: "auto",
        marginRight: "auto",
      }}
      src={LogoActive}
    />
  );
};

export default FormLogo;
