import React, { useState } from "react";
import {
  Box,
  Button,
  Stack,
  Typography,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { Container } from "reactstrap";
import { styled, useTheme } from "@mui/material/styles";
import Templates from "./Tabs/VerificationList";
import AddTemplateModal from './Tabs/AddTemplateModal';

const LinkBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  backgroundColor: '#fc57190d',
  padding: '0px 16px',
  borderRadius: theme.spacing(1.5),
  width: '50%',
  [theme.breakpoints.down('md')]: {
    width: '100%',
  },
}));

const IncomeVerification = () => {
  const theme = useTheme();
  const [isOpen, setIsOpen] = useState(false);

  const handleNewTemplate = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className="page-content">
      <Container fluid>
        <Stack
          direction={'row'}
          flexWrap={'wrap'}
          justifyContent={'space-between'}
          alignItems={'center'}
          sx={{ mb: 3 }}
        >
          <Typography variant="Body_light_20" fontWeight={600}>
            Verification List
          </Typography>
          <Button
            color="secondary"
            sx={{ width: 'fit-content' }}
            variant="outlined"
            startIcon={<AddIcon />}
            onClick={handleNewTemplate}
          >
            Verify New Account
          </Button>
        </Stack>
        <Box>
          <Templates isOpen={isOpen} />
        </Box>
      </Container>
      <AddTemplateModal isOpen={isOpen} handleNewTemplate={handleNewTemplate} />
    </div>
  );
};

export default IncomeVerification;